

<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="https://demos.creative-tim.com/vuetify-material-supportdashboard/favicon.ico"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>


  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'supportdashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-view-supportdashboard',
          title: 'supportdashboard',
          to: '/supportdashboard',
        },
        {
          icon: 'mdi-account',
          title: 'user',
          to: '/supportdashboard/pages/user',
        },
        {
          title: 'Orders',
          icon: 'mdi-clipboard-outline',
          to: '/supportdashboard/pages/order',
        },
        {
          icon: 'mdi-account',
          title: 'Product',
          to: '/supportdashboard/pages/product',
        },
        {
          icon: 'mdi-account',
          title: 'Group Product',
          to: '/supportdashboard/pages/groupproduct',
        },
        // {
        //   icon: 'mdi-account',
        //   title: 'Product Category',
        //   to: '/supportdashboard/pages/category',
        // },
        {
          icon: 'mdi-account',
          title: 'Company Information',
          to: '/supportdashboard/pages/companyinfo',
        },
        {
          icon: 'mdi-account',
          title: 'Contact Us',
          to: '/supportdashboard/pages/contractus',
        },
        {
          icon: 'mdi-account',
          title: 'FAQ',
          to: '/supportdashboard/pages/faq',
        },
        {
          icon: 'mdi-account',
          title: 'Banner',
          to: '/supportdashboard/pages/banner',
        },
        {
          icon: 'mdi-account',
          title: 'Advertisement',
          to: '/supportdashboard/pages/advertisement',
        },
        {
          icon: 'mdi-account',
          title: 'API',
          to: '/supportdashboard/pages/apis',
        },
        {
          icon: 'mdi-account',
          title: 'Email Configure',
          to: '/supportdashboard/pages/emailconfig',
        },
        {
          icon: 'mdi-account',
          title: 'Supports',
          to: '/supportdashboard/pages/supports',
        },
        
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
   <!-- <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      :expand-on-hover="expandOnHover"
      :right="$vuetify.rtl"
      mobile-break-point="960"
      app
      width="260"
      v-bind="$attrs"
    >
      <template v-slot:img="props">
        <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
      </template>

      <v-divider class="mb-1" />

      <v-list dense nav>
        <v-list-item>
          <v-list-item-avatar class="align-self-center" color="white" contain>
            <v-img
              src="https://demos.creative-tim.com/vuetify-material-supportdashboard/favicon.ico"
              max-height="30"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="display-1" v-text="profile.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="mb-2" />

      <v-list expand nav>
        <div />

        <template v-for="(item, i) in computedItems">
          <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
        <div />
      </v-list>

      <template v-slot:append>
        <base-item
          :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
        />
      </template>
    </v-navigation-drawer> -->
<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>









// {
//           title: 'icons',
//           icon: 'mdi-chart-bubble',
//           to: '/supportdashboard/components/icons',
//         },
//         {
//           title: 'google',
//           icon: 'mdi-map-marker',
//           to: '/supportdashboard/maps/google-maps',
//         },
//         {
//           title: 'notifications',
//           icon: 'mdi-bell',
//           to: '/supportdashboard/components/notifications',
//         },

        // {
        //   icon: 'mdi-account',
        //   title: 'Block/Unblock',
        //   to: '/supportdashboard/pages/block',
        // },
        // {
        //   icon: 'mdi-account',
        //   title: 'Sales',
        //   to: '/supportdashboard/pages/sales',
        // },
        // {
        //   icon: 'mdi-account',
        //   title: 'Analytics',
        //   to: '/supportdashboard/pages/analytics',
        // },